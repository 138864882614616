<template>
  <div :id='localId' class="k_p6_viewer">
    <div class="collapse_all_wrapper">
      <div class="collapse_box"><button v-ripple class="btn_collapse_close" @click="collapseAll(true)"><v-icon>mdi-plus</v-icon></button></div>
      <div class="collapse_box"><button v-ripple class="btn_collapse_close" @click="collapseAll(false)"><v-icon>mdi-minus</v-icon></button></div>
    </div>
    
    <div v-for="(lv1d, i) in itemsLv1()" :key="i" :class="`k_p6_sector lv${lv1d.LV_NO} ${isCollapsed(lv1d)?'opened':'closed'}`">
      <div class="k_p6_sector__header">
        <button v-if="isCollapsed(lv1d)" v-ripple class="btn_collapse_close" @click="collapse(lv1d)"><v-icon>mdi-minus</v-icon></button>
        <button v-else v-ripple class="btn_collapse_open" @click="collapse(lv1d)"><v-icon>mdi-plus</v-icon></button>
        <span class="sector_title">{{ lv1d.ACTIVITY_DESCR }}</span>
      </div>

      <div v-for="(lv2d, j) in itemsLv2(lv1d)" :key="j" :class="`k_p6_sector lv${lv2d.LV_NO} ${isCollapsed(lv2d)?'opened':'closed'}`">
        <div class="k_p6_sector__header">
          <button v-if="isCollapsed(lv2d)" v-ripple class="btn_collapse_close" @click="collapse(lv2d)"><v-icon>mdi-minus</v-icon></button>
          <button v-else v-ripple class="btn_collapse_open" @click="collapse(lv2d)"><v-icon>mdi-plus</v-icon></button>
          <span class="sector_title">{{ lv2d.ACTIVITY_DESCR }}</span>
        </div>

        <div v-for="(lv3d, k) in itemsLv3(lv2d)" :key="k" :class="`k_p6_sector lv${lv3d.LV_NO} ${isCollapsed(lv3d)?'opened':'closed'}`">
          <div class="k_p6_sector__header">
            <button v-if="isCollapsed(lv3d)" v-ripple class="btn_collapse_close" @click="collapse(lv3d)"><v-icon>mdi-minus</v-icon></button>
            <button v-else v-ripple class="btn_collapse_open" @click="collapse(lv3d)"><v-icon>mdi-plus</v-icon></button>
            <span class="sector_title">{{ lv3d.ACTIVITY_DESCR }}</span>
          </div>

          <div v-for="(lv4d, l) in itemsLv4(lv3d)" :key="l" :class="`k_p6_sector lv${lv4d.LV_NO} ${isCollapsed(lv4d)?'opened':'closed'}`">
            <div class="k_p6_sector__header">
              <button v-if="isCollapsed(lv4d)" v-ripple class="btn_collapse_close" @click="collapse(lv4d)"><v-icon>mdi-minus</v-icon></button>
              <button v-else v-ripple class="btn_collapse_open" @click="collapse(lv4d)"><v-icon>mdi-plus</v-icon></button>
              <span class="sector_title">{{ lv4d.ACTIVITY_DESCR }}</span>
            </div>

            <div v-for="(lv5d, m) in itemsLv5(lv4d)" :key="m" :class="`k_p6_sector lv${lv5d.LV_NO} ${isCollapsed(lv5d)?'opened':'closed'}`">
              <div class="k_p6_sector__header">
                <button v-if="isCollapsed(lv5d)" v-ripple class="btn_collapse_close" @click="collapse(lv5d)"><v-icon>mdi-minus</v-icon></button>
                <button v-else v-ripple class="btn_collapse_open" @click="collapse(lv5d)"><v-icon>mdi-plus</v-icon></button>
                <span class="sector_title">{{ lv5d.ACTIVITY_DESCR }}</span>
              </div>

              <p-6-activities 
                v-if="isCollapsed(lv5d)"
                :target="`${lv5d.LV1}${lv5d.LV2}${lv5d.LV3}${lv5d.LV4}${lv5d.LV5}`"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SafeIdMixin } from '@/mixins/safeid.mixin'
import { DBSupportService } from '@/services'

import P6Activities from '@/views/custom/P6/P6Activities'

export default {
  name: 'k-p6-viewer',
  mixins: [
    SafeIdMixin
  ],
  components: {
    P6Activities
  },
  data: () => ({
    queryLibService: null,
    itemsLevels: [],
    collapsed: {},
    collstorageName: 'P6_COLLAPSED_STATUS'
  }),
  created() {
    this.localId = this.id || 'k-p6-viewer__' + this.safeId('')
    this.queryLibService = new DBSupportService()
  },
  mounted() {
    this.queryLibService.getSqlQueryResult({
      idx: 0, 
      name: 'P6 Level Data',
      filters: ''
    }).then(d => {
      this.itemsLevels = d
      this.setCollapse()
    })
  },
  methods: {
    itemsLv1( ) { return this.itemsLevels.filter(item => item.LV_NO == 1) },
    itemsLv2(d) { return this.itemsLevels.filter(item => item.LV_NO == 2 && item.LV1 == d.LV1) },
    itemsLv3(d) { return this.itemsLevels.filter(item => item.LV_NO == 3 && item.LV1 == d.LV1 && item.LV2 == d.LV2) },
    itemsLv4(d) { return this.itemsLevels.filter(item => item.LV_NO == 4 && item.LV1 == d.LV1 && item.LV2 == d.LV2 && item.LV3 == d.LV3) },
    itemsLv5(d) { return this.itemsLevels.filter(item => item.LV_NO == 5 && item.LV1 == d.LV1 && item.LV2 == d.LV2 && item.LV3 == d.LV3 && item.LV3 == d.LV3 && item.LV4 == d.LV4) },

    setCollapse() {
      let collapsed_ = {}
      let storaged = localStorage.getItem(this.collstorageName)
      storaged = storaged ? JSON.parse(storaged) : {}

      this.itemsLevels.forEach(d => {
        let k_ = `${d.LV1}${d.LV2}${d.LV3}${d.LV4}${d.LV5}`
        collapsed_[k_] = !!storaged[k_]
      })

      this.collapsed = collapsed_
    },
    setCollapseStorage() {
      localStorage.setItem(this.collstorageName, JSON.stringify(this.collapsed))
    },
    isCollapsed(d) {
      return this.collapsed[`${d.LV1}${d.LV2}${d.LV3}${d.LV4}${d.LV5}`]
    },
    collapse(d) {
      let k_ = `${d.LV1}${d.LV2}${d.LV3}${d.LV4}${d.LV5}`

      this.collapsed[k_] = !this.collapsed[k_]
      this.setCollapseStorage()
    },
    collapseAll(collapse) {
      let keys = Object.keys(this.collapsed)
      let lv5Items = this.itemsLevels.filter(item => item.LV_NO == 5)
      let collapsed_ = {}

      keys.forEach(k => { collapsed_[k] = collapse })
      lv5Items.forEach(d => { collapsed_[`${d.LV1}${d.LV2}${d.LV3}${d.LV4}${d.LV5}`] = false })

      this.collapsed = collapsed_
      this.setCollapseStorage()
    }
  },
}
</script>

<style lang="stylus" scoped>
.k_p6_viewer {
  position: relative;

  .collapse_all_wrapper {
    position: absolute;
    top: 2rem;
    left: .2rem;

    .collapse_box {
      margin: .1rem;
    }
  }
}
.k_p6_sector {
  padding: 1px 0 0 5px;
  margin: -1px -1px 0 0;
  overflow: hidden;
  transition: height .15s;

  &.lv1 {
    background-color: #45c5e5;
    border: 1px solid #268fa9;
  }
  &.lv2 {
    background-color: #3cd995;
    border: 1px solid #23a069;
  }
  &.lv3 {
    background-color: #bddc41;
    border: 1px solid #8ca528;
  }
  &.lv4 {
    background-color: #e8b254;
    border: 1px solid #a3782c;
  }
  &.lv5 {
    background-color: #ed8a80;
    border: 1px solid #a8473d;
  }

  &.opened {
    padding-bottom: .5rem;
  }
  &.closed {
    height: 2.6rem;
  }

  .k_p6_sector__header {
    display: flex;
    margin-bottom: 2px;
    height: 2.2rem;
    align-items: center;

    .sector_title {
      display: block;
      margin-left: .5rem;
      font-weight: 500;
      font-size: 1.1rem;
      line-height: 1.3rem;
      color: #fff;
    }
  }
}
.btn_collapse_open,
.btn_collapse_close {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .75);

  .v-icon {
    font-size: 1.2rem;
    color: #000;
  }
}
</style>