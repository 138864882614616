<template>
  <table :id='localId'>
    <tr v-for="(item, i) in items" :key="i">
      <td>{{ item.NO }}</td>
      <td>{{ item.ACTIVITY_DESCR }}</td>
      <td>{{ item.ACTIVITY }}</td>
      <td>{{ item.ORIGINAL_DU }}</td>
      <td>{{ item.TOTAL_FLOAT }}</td>
      <td>{{ item.BL_SD }}</td>
      <td>{{ item.BL_FD }}</td>
      <td>{{ item.PLAN_SD }}</td>
      <td>{{ item.PLAN_FD }}</td>
      <td>{{ item.ACTUAL_SD }}</td>
      <td>{{ item.ACTUAL_FD }}</td>
    </tr>
  </table>
</template>

<script>
import { SafeIdMixin } from '@/mixins/safeid.mixin'
import { DBSupportService } from '@/services'

export default {
  name: 'k-p6-activities',
  mixins: [
    SafeIdMixin
  ],
  props: {
    target: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    queryLibService: null,
    items: [],
  }),
  created() {
    this.localId = this.id || 'k-map-board__' + this.safeId('')
    this.queryLibService = new DBSupportService()
  },
  mounted() {
    this.getActivities()
  },
  methods: {
    getActivities() {
      this.queryLibService.getSqlQueryResult({
        idx: 0, 
        name: 'P6 Activities',
        filters: `CONCAT([P6_LV1], [P6_LV2], [P6_LV3], [P6_LV4], [P6_LV5]) = '${this.target}'`
      }).then(d => {
        this.items = d
      })
    }
  }
}
</script>

<style>

</style>